import React, { useEffect } from "react";
import AboutHero from "../components/About/AboutHero";
import "../assets/css/about.css";
import SectionOne from "../components/About/SectionOne";
import SectionTwo from "../components/About/SectionTwo";
import Pastors from "../components/About/Pastors";
import Inspiration from "../components/About/Inspiration";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AboutHero />
      <SectionOne />
      <SectionTwo />
      <Pastors />
      <Inspiration />
    </>
  );
};

export default About;
