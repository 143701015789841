import React from "react";
import AnimatedText from "react-animated-text-content";
import HeroBanner from "./HeroBanner";

const Hero = () => {
  return (
    <div className="pt-2 pb-5">
      <div className="container">
        <div className="text-center pb-3">
          <h1 className="text-uppercase onebody-text">onebody church</h1>
          <div className="mx-auto mb-lg-5 mb-0" style={{ maxWidth: "610px" }}>
            <AnimatedText
              type="words" // animate words or chars
              animation={{
                x: "200px",
                y: "-20px",
                scale: 1.1,
                ease: "ease-in-out",
              }}
              animationType="float"
              interval={0.06}
              duration={0.1}
              tag="p"
              className="animated-paragraph"
              includeWhiteSpaces
              threshold={0.1}
            >
              Our Message To You is as simple as what Philip told Nathaniel when he found the Messiah, “…Come and See for Yourself…” John 1:46;
            </AnimatedText>
          </div>
        </div>
      </div>
      <HeroBanner />
    </div>
  );
};

export default Hero;
