import React from "react";
import { useNavigate } from "react-router-dom";
import "animate.css";

const menuItems = [
  { menu: "about", link: "about" },
  { menu: "love vision", link: "love-vision" },
  { menu: "contact us", link: "contact" },
  { menu: "Join Live", link: "live" },
  { menu: "Lord's Table", link: "lords-table" },
  { menu: "Give", link: "give" },
  { menu: "Charity", link: "charity" },
];

const Navbar = ({ showMenu, toggle }) => {
  const navigate = useNavigate();

  const selectMenu = (link) => {
    navigate(`/${link}`);
    toggle();
  };
  return (
    <nav className={`menu-wrapper animate__animated animate__fadeInDown ${!showMenu && "d-none"}`}>
      <div className="container">
        <ul className="list-unstyled">
          {menuItems.map((val, index) => {
            return (
              <li onClick={() => selectMenu(val.link)} className="d-flex">
                <span>({index + 1})</span>
                <span>{val.menu.toUpperCase()}</span>
              </li>
            );
          })}

          <a href="https://onebodychurchng.com" target="_blank" rel="noreferrer">
            <li className="d-flex">
              <span> ({menuItems.length + 1}) </span>
              <span>BLOG</span>
            </li>
          </a>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
