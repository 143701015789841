import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  allScreens: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

// manual data to be replace with api
const heroBanner = [
  {
    image: `https://i.ibb.co/MByJ8rv/hero-slider1.png`,
  },

  {
    image: `https://i.ibb.co/zNf1sLr/hero-slider3.jpg`,
  },

  {
    image: `https://i.ibb.co/mGx7rsM/hero-slider2.jpg`,
  },
];

const HeroBanner = () => {
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={3000}
      keyBoardControl={true}
      customTransition="transform 3000ms ease-in-out"
      transitionDuration={10000}
      focusOnSelect={true}
      swipeable={true}
      removeArrowOnDeviceType={["allScreens"]}
    >
      {heroBanner.map(({ image, responsiveImg }, key) => {
        return (
          <>
            <div
              key={key}
              className={`hero-banner d-lg-none ${key === 1 && "contain-an-image"}`}
              style={{ backgroundImage: `url(${responsiveImg ?? image})` }}
            ></div>
            <div
              key={key}
              className={`hero-banner d-lg-block d-none ${key === 1 && "contain-an-image"} `}
              style={{ backgroundImage: `url(${image})` }}
            ></div>
          </>
        );
      })}
    </Carousel>

    // <LazyLoadImage width="100%" src={Hero1} alt="hero-images" />
  );
};

export default HeroBanner;
