import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import MarqueeHeader from "../components/MarqueeHeader";

const imgApi = [
  { img: "https://i.ibb.co/QNLK11p/DSC07769.jpg" },
  { img: "https://i.ibb.co/jfScxw2/DSC07830.jpg" },
  { img: "https://i.ibb.co/dc003JC/DSC07836.jpg" },
  { img: "https://i.ibb.co/Vt2Mh4J/DSC07840.jpg" },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 2000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1700 },
    items: 4,
  },
  lg: {
    breakpoint: { max: 1700, min: 1200 },
    items: 3,
  },

  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const LordsTable = () => {
  return (
    <section>
      <MarqueeHeader title="Lord's Table" extra={false} />

      <div className="container mb-5">
        <p>
          The Lord’s Table commonly referred to as Holy communion as being practiced in our churches today is an abuse of what was instituted by our
          Lord Jesus Christ (Luke 22:15-20, 1 Cor 11:23). It has been turned into warfare meetings or another to get things from God, especially in
          the Pentecostal setting. This significant spiritual event has been oversimplified and has lost its essence, particularly, in times when the
          church is in dire need to find its center again - “by this shall all men know that ye are my disciples, when ye love one another” (John
          13:35). It is reduced to anything but remembrance of the Lord. Hence, the goal is to re-establish it as instituted by the Lord and enjoy the
          meaning, purpose, completeness, excitement of the entire spiritual event and indeed be refreshed. In an attempt to achieve all of the
          aforementioned, we will continue to make changes as the Lord leads us until we get it right because He clearly instituted it.
        </p>

        <p>
          Although, the practice in our churches today has similarities to what is established in the scripture, for instance, the significance of the
          Bread and Wine which is by far the most important part has been upheld. However, for the completeness to be achieved other parts are
          inevitable, just as our body is complete when all parts are available and working yet not disregarding that other parts are more important
          the other. To achieve completeness all parts must be involved and functioning. And for the Lord’s Table, we seek to achieve the fullness of
          the entire spiritual event.
        </p>
      </div>

      <Carousel responsive={responsive} infinite={true} autoPlay={true}>
        {imgApi.map((val, key) => {
          return <div key={key} style={{ backgroundImage: `url(${val.img})` }} className="about-carousel-img"></div>;
        })}
      </Carousel>

      <div className="container my-5">
        <p>
          From the scripture, we see that Jesus gave some instructions as to how and when it should be done. He followed a particular order while at
          the table and the apostles followed thus.
        </p>

        <h6>It was a meal - Luke 22:7-20. </h6>

        <p>
          The word “meal” was used in the following verses – Luke 22: 7,11,12,13,14,16,20 (Matt 26:18-19), it is evident that Jesus and His disciples
          had a meal and eaten as one at a table (vs 14,21). The Apostles acted similarly as done by Jesus 1Cor 11: 25,33, Acts 2:42. There were four
          major constant activities of the early church, the devotion to the apostles teaching, fellowship and the sharing of meals and prayer
          (sometimes we wonder why having done everything they do we don’t see their experiences, but have we?). In Acts 20:7, they met weekly at the
          Lord’s supper.
        </p>
        <h6>It was frequent, like very frequent</h6>

        <p>
          The references in Act 2:42 and Acts 20:7 made it clear that this meal was a fundamental part of the Christian weekly community activity.
          This is possibly due to the nature of the church - very localized and participatory. This perhaps explains why the church in Ephesus for
          example could not have met in one place weekly.
        </p>
        <h6>The Progression of the Event</h6>
        <ul>
          <li className="mt-2">
            In Luke 22:17, he took a first cup and shared it in thanksgiving. That was the last time he drank wine while on earth and he made it clear
            that he will not taste it again until the kingdom of God has come. This cup was not the one that represents the eternal covenant.
          </li>
          <li className="mt-2">
            Next, they began to eat and in the middle of the meal, he breaks the bread (Matt 26:26) and he explains its meaning.
          </li>
          <li className="mt-2">
            Then after the meal was over, he takes another cup and shares, this is the one that represents the new covenant marked by his blood.
            Matthew’s account does not separate these cups but we find evidence of it in 1Cor 11:25 where Paul expressly wrote “after supper”.
          </li>
          <li className="mt-2">Then they sang a song Matt 26:30</li>
        </ul>

        <h6>The Fulfillment of the Meaning of the Meal</h6>
        <p>
          Jesus started the night with these words “until its meaning is fulfilled in the Kingdom of God”. The reason why this meal was so important
          apart from the lessons of its elements is that it was supposed to be a reminder about another meal - “The Messianic Banquet” that will hold
          physically when the Lord returns. In Luke 22:28-29, he gives them a promise that they will sit at this “Table” again with him in his kingdom
          because they were true to him in his trials. He even promised them thrones (but all of these was not enough for Judas, all he wanted was the
          money).
        </p>

        <p>
          One of the reasons why we are recreating and re-establishing this meal is because one of the strategies of the enemy to reduce it to just a
          quickie event and even misdirecting its meaning and relevance is that this meal is actually the one activity that should remind believers
          constantly of what is waiting for them up ahead. Also, the participation in this meal weekly for them was a way to show their strong
          commitment to the Christian community. It was a powerful uniting platform where offenses were trashed and the fire of love was re-ignited.
          Also, the most senior ministers of the community were responsible for the actual sharing which reminded them Jesus’ words that night in Luke
          22:27. The early church apostles did not actually stop serving tables for it would not make sense that the work 12 persons could not do, 7
          would be able to do it. They just wanted to prioritize other roles like prayer and teaching because their inefficiency was already becoming
          obvious In Acts 6:1.
        </p>
      </div>
    </section>
  );
};

export default LordsTable;
