import React, { useState } from "react";
import LovaFront from "../../assets/images/Lova/lova-cover.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faFileAudio, faFilePdf } from "@fortawesome/free-solid-svg-icons";

const SectionThree = () => {
  const [showBookModal, setShowBookModal] = useState(false);

  return (
    <section className="py-md-5 py-3">
      <div className="container">
        <div className="row align-items-center gap-lg-0 gap-3">
          <div className="col-md text-center">
            <div className="position-relative book-image">
              <img className="img-fliud lova-cover-one d-xl-block d-none" src={LovaFront} alt="love-vision-book-cover" />
              <img className="img-fliud lova-cover-two " src={LovaFront} alt="love-vision-book-cover" />
            </div>
          </div>
          <div className="col-md">
            <h4 className="section-title">The Love Vision Book</h4>
            <p>
              If you’re about to read this book now, one thing I hope will be as clear to you as glass is the necessity to love. “For the law can be
              summed up in the one command, ‘love others as you love yourself’” Galatians 5:14 (TLB)
            </p>

            <button onClick={() => setShowBookModal(!showBookModal)} className="section-link bg-transparent">
              Buy Book
            </button>

            <div className={`book-option-modal shadow ${!showBookModal && "d-none"}`}>
              <a onClick={() => setShowBookModal(false)} rel="noreferrer" target="_blank" href="https://selar.co/thelovevision">
                <div className="d-flex gap-2 align-items-center">
                  <FontAwesomeIcon icon={faBook} />
                  <span>Hard Cover</span>
                </div>
              </a>

              <a onClick={() => setShowBookModal(false)} rel="noreferrer" target="_blank" href="https://selar.co/thelovevisionebook">
                <div className="d-flex gap-2 align-items-center">
                  <FontAwesomeIcon icon={faFilePdf} />
                  <span>E-Book</span>
                </div>
              </a>

              <a onClick={() => setShowBookModal(false)} rel="noreferrer" target="_blank" href="https://selar.co/thelovevisionaudiobook">
                <div className="d-flex gap-2 align-items-center">
                  <FontAwesomeIcon icon={faFileAudio} />
                  <span>Audio Book</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionThree;
