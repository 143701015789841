import { useEffect, useState } from "react";
import "../assets/css/live.css";

const Mixlr = () => {
  const [live, setLive] = useState("video");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="live-header">
        <div onClick={() => setLive("video")} className={`btn ${live.toLowerCase() === "video" && "active"}`}>
          Video
        </div>
        <div onClick={() => setLive("audio")} className={`btn ${live.toLowerCase() === "audio" && "active"}`}>
          Mixlr
        </div>
      </div>
      {live.toLowerCase() === "audio" ? (
        <>
          <div className="onebody-mixlr-wrapper">
            <iframe title="onebody church mixlr live stream" src="https://onebodychurchng.mixlr.com/embed" frameborder="0" scrolling="no"></iframe>
          </div>
          <div className="py-3 text-center">
            <a target="_blank" rel="noreferrer" className="section-link" href="https://onebodychurchng.mixlr.com/recordings">
              See Previous Recordings
            </a>
          </div>
        </>
      ) : (
        <>
          <div className="home-yt-iframe-wrapper mx-auto">
            <iframe
              loading="lazy"
              src="https://www.youtube.com/embed/live_stream?channel=UCNXbdFdh3p8G8ZOW53s0ozA"
              title="JOIN ONEBODY CHURCH // COME AND SEE JESUS // PASTOR TAIWO OYEMADE"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="py-3 text-center">
            <a target="_blank" rel="noreferrer" className="section-link" href="https://www.youtube.com/@onebodychurchng/streams">
              See Previous Recordings
            </a>
          </div>
        </>
      )}
    </>
  );
};

export default Mixlr;
