const SectionTwo = ({target}) => {
    return (
      <>
        <div>
          <h2 className="charity-header-1">2024/2025 Back-to-School Target</h2>
  
          <div className="charity-info">
            At ONEBODY Charity, we understand that times are tough, especially for
            indigent families who want to give their children an education. As a
            result, many teenagers across Southwest Nigeria are going to face the
            challenge of obtaining essential materials for the new school year.
            Some of them will have to skip classes to work in other to acquire
            these items. While some of them who are discouraged will drop out
            altogether. That’s why every year, we give out Back-to-School Packs to
            indigent teenagers to help motivate them to keep learning. Our target
            for the year 2024 is to give 5000 school packs. So far we have given
            out 500.
          </div>
        </div>
  
        <div>
          <h2 className="charity-header-1">How do you come in</h2>
  
          <div className="charity-info">
            In other to achieve our target of providing 5000 back-to-school packs
            for indigent teenagers across SouthWest Nigeria, we are trying to
            raise a sum of <span className="pri-color">30Million Naira</span>. You contribution will go a long way in
            helping us meet this target.
          </div>
        </div>
  
        <div ref={target} className="py-1">
        <div  className="account-card">
          <div className="share-heart-text">Please Share Your Heart</div>
  
          <div className="data">Account Name: <span>ONEBODY CHURCH</span></div>
          <div className="acct-no">2034723621</div>
          <div className="data">Bank Name: <span>FIRST BANK</span></div>
          <div className="data">Description: <span>ONEBODY CHARITY</span></div>
        </div>
        </div>
      </>
    );
  };
  
  export default SectionTwo;
  