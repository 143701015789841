import React from "react";

let imgLink = `https://i.ibb.co/1qPh9Mg/onebody-Music.jpg`;

const OnebodyMusic = () => {
  return (
    <section className="py-lg-5 py-3">
      <a href="https://bit.ly/m/onebodymusic/" target="_blank" rel="noreferrer">
        <div style={{ backgroundImage: `url(${imgLink})` }} className="onebody-music-img-frame">
          <div className="onebody-music-img-overlay">
            <div className="onebody-music-header">Music</div>
            <div className="text-end">
              <div className="fw-600 text-white">Listen Now</div>
              <p className="onebody-music-sub-header">New Album - Freedom</p>
            </div>
          </div>
        </div>
      </a>
    </section>
  );
};

export default OnebodyMusic;
