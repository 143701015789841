import React, { useEffect } from "react";
import Hero from "../components/Home/Hero";

import "../assets/css/home.css";
import About from "../components/Home/About";
import Services from "../components/Home/Services";
import Sermons from "../components/Home/Sermons";
import Partner from "../components/Home/Partner";
import LOVA from "../components/Home/LOVA";
import OnebodyMusic from "../components/Home/OnebodyMusic";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Hero />
      <About />
      <Services />
      <Sermons />
      <Partner />
      <LOVA />
      <OnebodyMusic />
    </>
  );
};

export default Home;
