import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import Icon from "../Icon";
import Navbar from "./Navbar";
import { faRectangleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const hideMenu = () => {
    setShowMenu(false);
  };
  return (
    <>
      <header className="py-3">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center logo-wrapper">
            <Link to="/">
              <img onClick={hideMenu} width="70" src={Logo} alt="onebody-logo" />
            </Link>

            <button onClick={toggleMenu} className="bg-transparent border-0">
              {showMenu ? <FontAwesomeIcon size="2x" className="text-white" icon={faRectangleXmark} /> : <Icon icon="menu" />}
            </button>
          </div>

          {/* social media text */}
          <div className="d-md-block d-none">
            <div className=" row justify-content-center mt-5">
              <div className="col-lg-7">
                <div className="d-flex justify-content-evenly social-text-wrapper">
                  <a rel="noreferrer" className="text-white" href="https://www.instagram.com/onebodychurchng/" target="_blank">
                    Instagram
                  </a>
                  <a rel="noreferrer" className="text-white" href="https://facebook.com/onebodychurchng" target="_blank">
                    Facebook
                  </a>
                  <a rel="noreferrer" className="text-white" href="https://twitter.com/Onebodyng" target="_blank">
                    Twitter
                  </a>
                  <a rel="noreferrer" className="text-white" href="https://www.youtube.com/@onebodychurchng" target="_blank">
                    Youtube
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Navbar toggle={toggleMenu} showMenu={showMenu} />
    </>
  );
};

export default Header;
