import React from "react";

const SectionTwo = () => {
  return (
    <section className="py-md-5 py-3">
      <div className="container">
        <div className="row">
          <div className="col-lg"></div>
          <div className="col-lg">
            <p>
              This 9week long program features weekly course material study, conversations and assessment. The main course material for the program is
              The Love Vision book written by Onebody church Pastor – Pastor Taiwo Oyemade. The book has 8 chapters and each chapter is reviewed
              weekly.
            </p>

            <p>
              In these chapters you will learn of the instructions given to us by our Lord Jesus pertaining to how we relate with our neighbors,
              strangers, and our brethren.
            </p>

            <p>
              Hence the teachings form our culture and what we believe the Lord wants us to exhibit to one another, His church at large and to our
              neighbors. We fellowship and serve one another in this understanding.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionTwo;
