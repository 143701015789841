
const SectionOne = () => {
    return (
      <div>
          <div>
            <h2 className="charity-header-1 mt-0">
              Empowering the Future: OneBody Charity's Back-to-School Initiative
            </h2>
            <div className="charity-info">
              At OneBody Charity, we understand that times are tough, especially
              for families preparing their children for school. Many teenagers
              across Southwest Nigeria are facing challenges in obtaining
              essential writing materials for the new school year. That’s why
              we’ve launched our <span className="pri-color">Back-to-School Pack Initiative</span> to help ease this
              burden.
            </div>
          </div>
  
          <div>
            <h2 className="charity-header-1">Our Vision</h2>
            <div className="charity-info">
              To help teenagers and young adults unlock a prosperous future
              through education.
            </div>
          </div>
  
          <div>
            <h2 className="charity-header-1">Our Mission</h2>
            <ul>
              <li className="charity-info">
                To provide back-to-school packs for teenagers in secondary
                schools.
              </li>
              <li className="charity-info">
                To organize digital skill awareness and acquisition programs for
                young adults.
              </li>
            </ul>
          </div>
  
          <div className="video-container">
          <iframe loading="lazy" src="https://www.youtube.com/embed/NtgRBxSnzY8" title="Onebody Charity" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
      </div>
    );
  };
  
  export default SectionOne;
  