import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const inspirationData = [
  {
    text: "Ephesians 4:4-7 – We derive the name ‘Onebody’ from here. We have come to know that the entire church is all deliberately created and fitted together by the Father under Jesus the Head. We know that we have one Spirit, one Lord, and one God. We know that we can never come to experience the fullness of the intention of God in isolation.",
  },

  {
    text: "John 17:20-22 – We want to witness the glory of God inherent in Jesus by uniting together, even as the trinity is glorious in their unity. We understand that it is in our perfect unity that the world will know that the Father has sent his Son Jesus Christ.",
  },

  {
    text: "1Corinthians 12 – Continuously shows us that each one of us (individual/denomination) has been given special capacities by God through his Holy Spirit so that we being many parts in joining together can become one, even the body of Christ as described in verses 12-15.",
  },
];

const churchWeSeeData = [
  {
    text: "We see a vast innumerable number of believers who are united under Jesus only. People who are not held back by the walls of denomination, tribe, nationality, tradition or doctrine but are willing to be vulnerable under the grace of that is revealed in Christ and the adventure of winning others into the Kingdom of God through the help of the Holy Spirit.",
  },
  {
    text: "We also see a regular congregation of peoples to sing, worship and recommit themselves to Jesus and His cause of establishing God’s kingdom on earth in deep understanding of His truths.",
  },
  {
    text: "We see a body that is no longer committed to building organizations, titles and buildings but people, families and relationships, empowering more and more people with tangible skills to help them birth spiritual and social relevance in their local settings as they convey to all around them the message of God’s kingdom in a simple and clear manner, especially through the lives they live.",
  },
  {
    text: "We see a body that is not afraid to confront the evil of its generation and participate in its positive social responsibility of inculcating Godly values such as equality, integrity, accountability, faithfulness, perseverance, humility, and social justice.",
  },
];

const Inspiration = () => {
  return (
    <section className="py-lg-5 py-3">
      <div className="container">
        <div className="row gap-lg-0 gap-5">
          <div className="col-lg-6">
            <div className="section-title">our inspiration</div>
            <p>We want you to know that we have been greatly motivated by the following scriptures:</p>

            <VerticalTimeline layout="1-column-left">
              {inspirationData.map((data, key) => {
                return (
                  <VerticalTimelineElement
                    key={key}
                    className="vertical-timeline-element--work about-timeline"
                    contentStyle={{ background: "none", color: "#fff" }}
                    contentArrowStyle={{ border: "none" }}
                    iconStyle={{ background: "#fff", color: "#fff" }}
                  >
                    <p>{data.text}</p>
                  </VerticalTimelineElement>
                );
              })}
            </VerticalTimeline>
          </div>
          <div className="col-lg-6">
            <div className="section-title">the church we see</div>
            <VerticalTimeline layout="1-column-left">
              {churchWeSeeData.map((data, key) => {
                return (
                  <VerticalTimelineElement
                    key={key}
                    className="vertical-timeline-element--work about-timeline"
                    contentStyle={{ background: "none", color: "#fff" }}
                    contentArrowStyle={{ border: "none" }}
                    iconStyle={{ background: "#fff", color: "#fff" }}
                  >
                    <p>{data.text}</p>
                  </VerticalTimelineElement>
                );
              })}
            </VerticalTimeline>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Inspiration;
