import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="text-center text-uppercase">
        <h1>Once You were lost now you are found</h1>
        <p>Please head back home</p>

        <Link className="pri-btn" to="/about">
          Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
