import React, { useEffect } from "react";
import SectionOne from "../components/LOVA/SectionOne";
import "../assets/css/lova.css";
import SectionTwo from "../components/LOVA/SectionTwo";
import SectionThree from "../components/LOVA/SectionThree";
const LOVA = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
    </>
  );
};

export default LOVA;
