import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/alt-logo.png";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row gap-lg-0 gap-3">
          <div className="col-lg-4">
            <div>
              <img width={150} src={Logo} alt="onebody-alt-logo" />
            </div>
            <address>
              <span className="pri-color fw-bold"> Lagos Branch </span> <br />
              <span>20 Adebiyi Street, off lawani Street, Akoka Yaba.</span>
            </address>
            <address>
              <span className="pri-color fw-bold"> Abeokuta Branch </span> <br />
              <span>Gemini Building, Friendly Close, Elite Rd. Idi Aba, Abeokuta.</span>
            </address>
          </div>

          <div className="col-lg-4">
            <div className="row ">
              <div className="col footer-link-wrapper">
                <p className="fw-bold">Resources</p>
                <div>
                  <a rel="noreferrer" target="_blank" href="https://music.onebodychurchng.com">
                    Onebody Music
                  </a>
                </div>
                <div>
                  <a rel="noreferrer" target="_blank" href="https://www.youtube.com/@onebodyfilms">
                    Onebody Films
                  </a>
                </div>
                <div>
                  <a rel="noreferrer" target="_blank" href="https://podcasters.spotify.com/pod/show/onebody-church/">
                    Onebody Podcast{" "}
                  </a>
                </div>
              </div>
              <div className="col footer-link-wrapper">
                <p className="fw-bold">Pages</p>
                <div>
                  <Link to="/about">About</Link>
                </div>
                <div>
                  <Link to="/lords-table">Lord's Table </Link>
                </div>
                <div>
                  <Link to="partner">Partner With Us </Link>
                </div>
                <div>
                  <Link to="/love-vision">LOVA</Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div>
              <iframe
                title="Onebody address map "
                width="100%"
                height="240"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=470&amp;hl=en&amp;q=onebody%20church+(Onebody%20Church)&amp;t=&amp;z=19&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              >
                <a href="https://www.maps.ie/distance-area-calculator.html">measure area map</a>
              </iframe>
            </div>
          </div>
        </div>
        <div className="text-center">Copyright &copy; {new Date().getFullYear()} Onebody Church</div>
      </div>
    </footer>
  );
};

export default Footer;
