import React, { useState } from "react";
import Marquee from "react-easy-marquee";
import PT from "../../assets/images/pastors/pastor-taiwo.jpg";
import PTMobile from "../../assets/images/pastors/pastor-taiwo.webp";
import PL from "../../assets/images/pastors/pastor-lanre.jpg";
import PLMobile from "../../assets/images/pastors/pastor-lanre.webp";
import JK from "../../assets/images/pastors/pastor-jk.jpg";
import JKMobile from "../../assets/images/pastors/pastor-jk.webp";
import PastorModal from "../modals/pastorModal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Asterisk from "../../assets/images/Asterisk.svg";

// to be replaced with state derived from the store
const PastorsDetails = [
  {
    name: "Taiwo Oyemade",
    description: `Taiwo is the Church Pastor at Onebody Church. He leads the Teaching and Spiritual Equipping at Onebody church. He is also the director of
        Onebody Films. He is married to Michelle and they are blessed with two kids.`,
    facebook: "https://web.facebook.com/taiwopeteroyemade",
    instagram: "https://www.instagram.com/taiwooyemade/",
    twitter: "https://twitter.com/taiwooyemade",
    linkedIn: "https://www.linkedin.com/in/taiwooyemade/",
    img: PT,
    imgMobile: PTMobile,
    role: "church pastor",
  },

  {
    name: "John Kenny",
    description: `John Kenny is the Worship Pastor at Onebody Church. He is also the director of Onebody Music. He a prolific song writer and music producer stirring deeper worship experiences with the body of Christ. He is married to Tosin and they are blessed with two children.`,
    facebook: "https://web.facebook.com/oyemadekenny",
    instagram: "https://www.instagram.com/johnkennyfreedom/",
    twitter: "https://twitter.com/johnkennyfirst",
    linkedIn: "https://www.linkedin.com/in/oyemadekehinde/",
    img: JK,
    imgMobile: JKMobile,
    role: "worship pastor",
  },
  {
    name: "Omomeji Olarewaju",
    description: `Olarewaju is the pastor of Administration in Onebody church. He is a lecturer at the Department of Geography, University of Lagos, Akoka. He is married to Boluwatife.`,
    facebook: "https://web.facebook.com/olarewaju.omomeji",
    instagram: "https://www.instagram.com/lanre_omomeji/",
    twitter: "https://twitter.com/OmomejiE",
    linkedIn: "https://www.linkedin.com/in/olarewaju-omomeji-156671a1/",
    img: PL,
    imgMobile: PLMobile,
    role: "admin pastor",
  },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1200 },
    items: 3,
  },

  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Pastors = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPastorDetails, setSelectedPastorDetails] = useState(null);

  const showModal = (id) => {
    setSelectedPastorDetails(PastorsDetails[id]);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <section className="py-lg-5 py-3">
      <header className="service-marquee-wrapper">
        <Marquee className="py-lg-5 py-0" duration="10000" align="center">
          <div className="services-marque-header">
            <div className="d-flex justify-content-evenly align-items-center">
              <img className="rotate" src={Asterisk} alt="" />
              <span> Our Pastors</span>
            </div>
          </div>

          <div className="services-marque-header">
            <div className="d-flex justify-content-evenly align-items-center">
              <img className="rotate" src={Asterisk} alt="" />
              <span> Our Pastors</span>
            </div>
          </div>
        </Marquee>

        <Marquee className="py-lg-5 py-0" duration="10000" align="center" reverse="true">
          <div className="services-marque-header">
            <div className="d-flex justify-content-evenly align-items-center">
              <img className="rotate" src={Asterisk} alt="" />
              <span> Our Pastors</span>
            </div>
          </div>

          <div className="services-marque-header">
            <div className="d-flex justify-content-evenly align-items-center">
              <img className="rotate" src={Asterisk} alt="" />
              <span> Our Pastors</span>
            </div>
          </div>
        </Marquee>
      </header>
      <div className="container mt-4">
        <PastorModal pastorInfo={selectedPastorDetails} isModalOpen={isModalOpen} handleCancel={handleCancel} />
        <Carousel autoPlay={false} responsive={responsive}>
          {PastorsDetails.map((data, key) => {
            return (
              <div style={{ cursor: "pointer" }} onClick={() => showModal(key)} key={key}>
                <div
                  style={{ backgroundImage: window.innerWidth < 800 ? `url(${data.imgMobile})` : `url(${data.img})` }}
                  className="pastor-pic-frame mx-auto"
                ></div>
                <div className="pastor-details">
                  <div className="name">{data.name}</div>
                  <div>{data.role}</div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
};

export default Pastors;
