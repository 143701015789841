import React from "react";
import { Link } from "react-router-dom";
import Icon from "../Icon";

const About = () => {
  return (
    <section className="pb-lg-5 pb-3">
      <div className="container">
        <header>
          <div className="section-title mb-3">about onebody</div>
          <h2 className="section-header text-uppercase">We Are A Church That Believes In And Preaches Jesus Christ</h2>
        </header>
        <p>
          In our community, the message of and about the teachings of Jesus Christ takes center stage. Here, Jesus is our conversation, culture,
          aspiration, and our everything. You will find Jesus here!
        </p>

        <div className="mb-5">
          <Link className="section-link" to="/about">
            Read more <Icon icon="upRight" />
          </Link>
        </div>

        <div className="home-yt-iframe-wrapper mx-auto">
          <iframe
            loading="lazy"
            src="https://www.youtube.com/embed/qdDPB-RlHgc?showinfo=0&controls=0"
            title="JOIN ONEBODY CHURCH // COME AND SEE JESUS // PASTOR TAIWO OYEMADE"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      -
    </section>
  );
};

export default About;
