import { useState, useEffect } from "react";
import MarqueeHeader from "../components/MarqueeHeader";
import "../assets/css/give.css";
import downIcon from "../assets/images/Vector.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let imgHero = `https://i.ibb.co/KGLx4fP/give.png`;
const branchList = ["Lagos", "Abeokuta"];

const lagAcct = [
  { acctName: "onebody church", bankName: "Sterling Bank", acctNum: `0092743365` },

  {
    acctName: "onebody church",
    bankName: "First Bank",
    acctNum: `2034723621`,
  },
];

const abkAcct = [
  {
    acctName: "onebody church",
    bankName: "Sterling Bank",
    acctNum: `0094243924`,
  },
];

const foreignAcct = [
  { acctName: "onebody church", bankName: "Sterling Bank (dollar)", acctNum: `0092757542` },

  {
    acctName: "onebody church ",
    bankName: "Sterling Bank (pounds)",
    acctNum: `0092757528`,
  },

  {
    acctName: "onebody church ",
    bankName: "Sterling Bank (euro)",
    acctNum: `0092757535`,
  },
];

const notify = () => toast("Account Number Copied");

const copyAcctNum = (acct) => {
  navigator.clipboard.writeText(acct);
  notify();
};

const Give = () => {
  const [branch, setBranch] = useState(null);
  const [branchTab, setBranchTab] = useState(false);
  const [foreignTab, setForeignTab] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleBranchTab = () => {
    setBranchTab(!branchTab);
  };

  const toggleForeignTab = () => {
    setForeignTab(!foreignTab);
  };

  const hideBranchTab = (y) => {
    setBranchTab(false);
    setBranch(y);
  };
  return (
    <section>
      <MarqueeHeader title="Giving" extra={true} />
      <div className="container">
        <div style={{ backgroundImage: `url(${imgHero})` }} className="give-hero">
          <div className="give-hero-overlay">
            <div className="give-hero-content">
              <h2>Your cheerful giving will definitely make a difference!</h2>
              <p>
                2 Corinthians 9:7 reminds us that God loves a cheerful giver. We invite you to join us in spreading love, supporting our church's
                mission, and making a lasting impact.
              </p>

              <p>Every contribution counts, fueling our efforts to serve, inspire, and bring people to meet with Christ.</p>
            </div>
          </div>
        </div>

        <div className="payment-method-wrapper">
          <h4>Please select a branch</h4>

          <div className="select-bar">
            {branch === null ? "Select" : branch}
            <button onClick={toggleBranchTab}>
              <img src={downIcon} alt="down-icon" />
            </button>
          </div>

          <div className={`branch-option-wrapper animate__animated animate__fadeInUp ${!branchTab && "d-none"}`}>
            {branchList.map((branch, key) => {
              return (
                <div className="branch-option" onClick={() => hideBranchTab(branch)} key={key}>
                  {branch}
                </div>
              );
            })}
          </div>
        </div>

        {branch != null && (
          <div className="my-5 acct-card">
            {(branch === "Lagos" ? lagAcct : abkAcct).map((acct, key) => (
              <div key={key} className="gap-md-0 gap-2 d-flex justify-content-between align-items-center my-3 flex-wrap">
                <div className="acct-details">
                  <div className="bank-name">{acct.bankName}</div>
                  <div className="acct-name">{acct.acctName.toUpperCase()}</div>
                  <div className="acct-num">{acct.acctNum}</div>
                </div>
                <button
                  onClick={() => {
                    copyAcctNum(acct.acctNum);
                  }}
                  className="acct-copy-btn"
                >
                  Copy
                </button>
              </div>
            ))}

            <div className="text-center ">
              <button onClick={toggleForeignTab} className="pri-color fw-bold">
                {!foreignTab ? "see" : "hide"} foreign account details
              </button>
            </div>

            {foreignTab &&
              foreignAcct.map((acct, key) => (
                <div key={key} className="gap-md-0 gap-2 d-flex justify-content-between align-items-center my-3 flex-wrap">
                  <div className="acct-details">
                    <div className="bank-name">{acct.bankName}</div>
                    <div className="acct-name">{acct.acctName.toUpperCase()}</div>
                    <div className="acct-num">{acct.acctNum}</div>
                  </div>
                  <button
                    onClick={() => {
                      copyAcctNum(acct.acctNum);
                    }}
                    className="acct-copy-btn"
                  >
                    Copy
                  </button>
                </div>
              ))}
            <ToastContainer />
          </div>
        )}
      </div>
    </section>
  );
};

export default Give;
