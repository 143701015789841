import { Modal } from "antd";
import "../../assets/css/Modal/pastorModal.css";
import { faFacebookF, faInstagram, faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PastorModal = ({ isModalOpen, handleCancel, pastorInfo }) => {
  return (
    <Modal footer={null} open={isModalOpen} onCancel={handleCancel}>
      {pastorInfo === null ? (
        "loading..."
      ) : (
        <div className="pastor-modal-wrapper">
          <div
            style={{ backgroundImage: window.innerWidth < 800 ? `url(${pastorInfo.imgMobile})` : `url(${pastorInfo.img})` }}
            className="pastor-modal-pic"
          ></div>
          <div>
            <h4 className="pastor-name">{pastorInfo?.name}</h4>
            <div>{pastorInfo.description}</div>
            <div className="d-flex gap-2 mt-3">
              <a rel="noreferrer" href={pastorInfo.facebook} target="_blank">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a rel="noreferrer" href={pastorInfo.instagram} target="_blank">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a rel="noreferrer" href={pastorInfo.twitter} target="_blank">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a rel="noreferrer" href={pastorInfo.linkedin} target="_blank">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
export default PastorModal;
