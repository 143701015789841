import React from "react";
import Marquee from "react-easy-marquee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import LBanner from "../../assets/images/Lova/lova-banner.png";

const SectionOne = () => {
  return (
    <section>
      <header>
        <Marquee className="py-lg-5 py-0" duration="10000" align="center">
          <div className="services-marque-header">
            <FontAwesomeIcon className="rotate mx-3" icon={faAsterisk} /> Love Vision
          </div>

          <div className="services-marque-header">
            <FontAwesomeIcon className="rotate mx-3" icon={faAsterisk} /> Love Vision
          </div>
        </Marquee>
      </header>

      <div className="py-3">
        <div className="container">
          <p>
            The Love Vision Academy by Onebody was created to intimate Christians with God’s vision for his church. We believe you will get the chance
            to equip yourself with adequate Christ and Kingdom centered knowledge that will propel you to more meaningful and purposeful service.{" "}
          </p>

          <p>
            Also, it is to help everyone that joins us get acquainted with the Word of Christ to us, that which truly matters and forms an integral
            part upon which our community is built on. This is how we help people understand what matters to our Lord Jesus Christ and we remind
            ourselves of it so as to constantly obey His words.
          </p>
        </div>
      </div>

      <div style={{ backgroundImage: `url(${LBanner})` }} className="lova-banner"></div>
    </section>
  );
};

export default SectionOne;
