import React from "react";
import Marquee from "react-easy-marquee";
import Aste from "../assets/images/Asterisk.svg";

const MarqueeHeader = ({ title, extra }) => {
  return (
    <header>
      <Marquee className="py-lg-5 py-0" duration="10000" align="center" reverse="true">
        <div className="services-marque-header">
          <div className="d-flex align-items-center">
            <img className="rotate mx-3" src={Aste} alt="" />
            <span>{title}</span>
          </div>
        </div>

        <div className="services-marque-header">
          <div className="d-flex align-items-center">
            <img className="rotate mx-3" src={Aste} alt="" />
            <span>{title}</span>
          </div>
        </div>

        {extra && (
          <div className="services-marque-header">
            <div className="d-flex align-items-center">
              <img className="rotate mx-3" src={Aste} alt="" />
              <span>{title}</span>
            </div>
          </div>
        )}
      </Marquee>
    </header>
  );
};

export default MarqueeHeader;
