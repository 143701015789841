import React from "react";
import Icon from "../Icon";

const ReachUs = () => {
  return (
    <div className="reach-card">
      <div>Reach Out to Us</div>

      <div className="reach-details">
        <span className="icon">
          {" "}
          <Icon icon="locationPin" />
        </span>
        <span className="content">
          20, Adebiyi Street, (off Lawani Street, Before Unilag Main Gate)
          Akoka, Yaba, Lagos
        </span>
      </div>

      <div className="reach-details">
        <span className="icon">
          {" "}
          <Icon icon="phone" />
        </span>
        <span className="content">
          <a href="tel:+2348082907215">+2348082907215</a> ,
          <a href="tel:+2347084030632">+2347084030632</a>
        </span>
      </div>

      <div className="reach-details">
        <span className="icon">
          {" "}
          <Icon icon="email" />
        </span>
        <span className="content">
          <a href="mailto:onebodychurchng@gmail.com">
            onebodychurchng@gmail.com
          </a>
        </span>
      </div>
    </div>
  );
};

export default ReachUs;
