import React from "react";

const SectionTwo = () => {
  return (
    <section className="py-lg-5 py-3">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-5">
            <div className="mb-5">
              <h2 className="section-title">our mission</h2>
              <p>
                To propagate Jesus’ message of God’s Kingdom through adequate teaching and creation of spiritual music, concerts, retreats, and
                conferences, raising and empowering disciples of Jesus and commissioning them to live as light to the world.
              </p>
            </div>

            <div>
              <h2 className="section-title">our vision</h2>
              <p>To come to Unity in Love for God and all men; in faith in Jesus and in Hope of the glory we would receive at His appearance.</p>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="about-iframe-wrapper mx-auto">
              <iframe
                src="https://www.youtube.com/embed/qdDPB-RlHgc?autoplay=0&controls=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0"
                title="JOIN ONEBODY CHURCH // COME AND SEE JESUS // PASTOR TAIWO OYEMADE"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionTwo;
