import React from "react";

const SectionOne = () => {
  return (
    <section className="py-lg-5 py-3">
      <div className="container">
        <div className="row gap-lg-0 gap-2">
          <div className="col-lg-5">
            <div className="section-title">About Onebody</div>
          </div>
          <div className="col-lg-7">
            <p>
              We opened May, 5th, 2019 in Yaba, Lagos. Founded by the leading of the Holy Spirit, it has become a platform for intense discipleship
              into the culture of the Kingdom of God.
            </p>
            <p>
              More and more young people who are very persuaded to be a part of Onebody are joining the church to deepen their experience. Here at the
              church we hold bi-weekly meetings and seasonal weekend teaching and worship retreats.
            </p>
            <p>
              The church’s space too has also become Onebody’s creative space as we have shot a number of music videos as well as scenes in Onebody
              film stories. We also hold training for young people there.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionOne;
