import React from "react";
import Video from "../../assets/images/video.png";
import { Link } from "react-router-dom";

const Sermons = () => {
  return (
    <section className="py-lg-5 py-3">
      <div className="container">
        <div className="section-title mb-3">Listen To Sermons</div>
        <h2>Join any of our live services or catch up with any of our previous sermons. Click the button below.</h2>

        <div className="d-flex flex-wrap gap-4 mt-4">
          <Link to="/live" className="pri-btn">
            Join Us Live{" "}
            <span>
              <img width="25" src={Video} alt="video-icon" />
            </span>{" "}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Sermons;
