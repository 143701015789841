import React from "react";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import P1 from "../../assets/images/partner1.jpg";
import P2 from "../../assets/images/partner2.jpg";

const Partner = () => {
  return (
    <section className="pt-3 pb-4">
      <div className="container">
        <h3 className="section-title mb-3">Partner With Onebody</h3>
        <div className="row justify-content-between">
          <div className="col-lg-5 col-12 ">
            <div className="position-relative partner-col-img-wrapper">
              <div style={{ backgroundImage: `url(${P1})` }} className="partner-img-frame w-100"></div>
              <div style={{ backgroundImage: `url(${P2})` }} className="position-absolute bottom-0 end-0 d-lg-none partner-small-img-frame"></div>
            </div>
          </div>
          <div className="col-lg-7 d-lg-block d-none">
            <div style={{ backgroundImage: `url(${P2})` }} className="partner-img-frame"></div>
          </div>
        </div>

        <p className="mt-2">
          We have done a lot in the last 8years as a ministry with the help of countless individuals and families God has sent our way. There’s a lot
          more God wants to do through us and we realize that he has prepared more people to help us deliver his purposes . We believe we have a very
          fertile ground God personally tills and every seed sown grows tremendously well on it. We want you to sow seeds to Onebody through the
          following ways.
        </p>

        <div>
          <Link className="section-link" to="/give">
            Here's How <Icon icon="upRight" />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Partner;
